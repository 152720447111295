/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';

import { Divider, Grid } from 'semantic-ui-react';

import { readableColor } from 'polished';

import Header from 'components/ui/Header';
import * as Buttons from 'components/ui/button';
import { Checkbox, LightCheckbox } from 'components/ui/inputs/Checkbox';
import { SelectBox, StyledDropdown } from 'components/ui/inputs/Dropdown';
import { TextInput } from 'components/ui/inputs/TextInput';
import { PageLayout } from 'components/ui/layout/Page';

import { getInterpolatedColor } from 'utils/colors';

import * as styleVariables from 'assets/style/variables';

const alertClickComponent = (componentName) => () =>
  alert(`Clicked on ${componentName}`); // eslint-disable-line no-alert

const getCleanName = (text, toReplace) =>
  text
    .replace(new RegExp(toReplace, 'i'), '')
    .split(/(?=[A-Z])/)
    .join(' ');

const renderColor = (hex, name) => (
  <div style={{ padding: styleVariables.spaceMedium }}>
    <div
      style={{
        textAlign: 'center',
        backgroundColor: hex,
        color: readableColor(hex),
      }}
    >
      {hex}
    </div>
    {name ? <i>{getCleanName(name, 'color')}</i> : null}
  </div>
);

export default class ComponentDesignPage extends Component {
  constructor() {
    super();
    this.state = {
      selectedItem: '',
      boxSelectedItem: '',
    };
  }

  renderColors() {
    return (
      <Grid.Row columns={4}>
        <Grid.Column>
          {renderColor(styleVariables.colorPrimary, 'Primary')}
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.colorSecondary, 'colorSecondary')}
        </Grid.Column>
        <Grid.Column>
          {renderColor(
            styleVariables.colorSecondaryLighter,
            'colorSecondaryLighter'
          )}
        </Grid.Column>
        <Grid.Column>
          {renderColor(
            styleVariables.colorSecondaryLightest,
            'colorSecondaryLightest'
          )}
        </Grid.Column>
        <Grid.Column width={16}>
          <Divider />
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.colorComplementary, 'colorComplementary')}
        </Grid.Column>
        <Grid.Column width={16}>
          <Divider />
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.colorSuccess, 'colorSuccess')}
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.colorDanger, 'colorDanger')}
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.colorWarning, 'colorWarning')}
        </Grid.Column>
        <Grid.Column width={16}>
          <Divider />
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.colorBlack, 'colorBlack')}
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.colorDarkGrey, 'colorDarkGrey')}
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.colorGrey, 'colorGrey')}
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.colorGreyMedium, 'colorGreyMedium')}
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.colorLightGrey, 'colorLightGrey')}
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.colorLighterGrey, 'colorLighterGrey')}
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.colorWhite, 'colorWhite')}
        </Grid.Column>
        <Grid.Column width={16}>
          <Divider>
            <Header as="h4">Sentiment colors</Header>
          </Divider>
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.absoluteMaxColor, 'absoluteMaxColor')}
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.absoluteMidColor, 'absoluteMidColor')}
        </Grid.Column>
        <Grid.Column>
          {renderColor(styleVariables.absoluteMinColor, 'absoluteMinColor')}
        </Grid.Column>
        <Grid.Column width={16}>
          <Header as="h5">Sentiment colors gradient</Header>
          {Array(201)
            .fill()
            .map((_, ind) => (
              <span
                key={`${_}`}
                style={{
                  width: '0.49%',
                  height: '40px',
                  display: 'inline-flex',
                  backgroundColor: getInterpolatedColor((ind - 100) / 100),
                }}
              />
            ))}
        </Grid.Column>
        <Grid.Column width={16}>
          <Divider>
            <Header as="h4">Wordcloud sentiment colors</Header>
          </Divider>
        </Grid.Column>

        <Grid.Column>
          {renderColor(
            styleVariables.wordCloudAbsoluteMaxColor,
            'wordCloudAbsoluteMaxColor'
          )}
        </Grid.Column>
        <Grid.Column>
          {renderColor(
            styleVariables.wordCloudAbsoluteMidColor,
            'wordCloudAbsoluteMidColor'
          )}
        </Grid.Column>
        <Grid.Column>
          {renderColor(
            styleVariables.wordCloudAbsoluteMinColor,
            'wordCloudAbsoluteMinColor'
          )}
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderButtons = () => (
    <Grid.Row columns={2}>
      {Object.keys(Buttons).map((buttonName) => {
        const ButtonComponent = Buttons[buttonName];
        return (
          <Grid.Column
            key={`${buttonName}`}
            style={{ padding: styleVariables.spaceNormal }}
          >
            <ButtonComponent onClick={alertClickComponent(buttonName)}>
              {getCleanName(buttonName, 'button')}
            </ButtonComponent>
          </Grid.Column>
        );
      })}
    </Grid.Row>
  );

  renderInputs = () => (
    <Grid.Row columns={2}>
      <Grid.Column
        width={16}
        style={{ paddingBottom: styleVariables.spaceLarge }}
      >
        <Header as="h4">Text</Header>
        <Grid.Column>
          <TextInput />
        </Grid.Column>
      </Grid.Column>
      <Grid.Column
        width={16}
        style={{ paddingBottom: styleVariables.spaceLarge }}
      >
        <Header as="h4">Checkboxes</Header>
        <Header as="h5">Normal</Header>
        <Grid.Column>
          <Checkbox />
        </Grid.Column>
        <Header as="h5">Light</Header>
        <Grid.Column>
          <LightCheckbox />
        </Grid.Column>
      </Grid.Column>
    </Grid.Row>
  );

  renderDropDowns = () => {
    const { selectedItem, boxSelectedItem } = this.state;
    return (
      <Grid.Row columns={2}>
        <Grid.Column
          width={16}
          style={{ paddingBottom: styleVariables.spaceLarge }}
        >
          <Header as="h4">Dropdown</Header>
          <StyledDropdown
            options={[
              { text: 'Item 1', key: 'Item 1', value: 'Item 1' },
              { text: 'Item 2', key: 'Item 2', value: 'Item 2' },
              { text: 'Item 3', key: 'Item 3', value: 'Item 3' },
              { text: 'Item 4', key: 'Item 4', value: 'Item 4' },
            ]}
            onChange={(e, data) => this.setState({ selectedItem: data.value })}
            placeholder="A placeholder"
            help="A help message"
            value={selectedItem}
          />
        </Grid.Column>
        <Grid.Column
          width={16}
          style={{ paddingBottom: styleVariables.spaceLarge }}
        >
          <Header as="h4">Simple select</Header>
          <SelectBox
            options={[
              { text: 'Item 1', key: 'Item 1', value: 'Item 1' },
              { text: 'Item 2', key: 'Item 2', value: 'Item 2' },
              { text: 'Item 3', key: 'Item 3', value: 'Item 3' },
              { text: 'Item 4', key: 'Item 4', value: 'Item 4' },
            ]}
            onChange={(e, data) =>
              this.setState({ boxSelectedItem: data.value })
            }
            placeholder="A placeholder"
            label="A label"
            help="A help message"
            value={boxSelectedItem}
          />
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderTextElements = () => {
    const fontSizes = [
      ['XSmall', styleVariables.fontSizeXSmall],
      ['Small', styleVariables.fontSizeSmall],
      ['Smaller', styleVariables.fontSizeSmaller],
      ['Base', styleVariables.fontSizeBase],
      ['Large', styleVariables.fontSizeLarge],
      ['XLarge', styleVariables.fontSizeXLarge],
      ['Big', styleVariables.fontSizeBig],
    ];
    const fontWeights = [
      ['Base', styleVariables.fontWeightBase],
      ['Medium', styleVariables.fontWeightMedium],
      ['SemiBold', styleVariables.fontWeightSemiBold],
      ['Bold', styleVariables.fontWeightBold],
      ['ExtraBold', styleVariables.fontWeightExtraBold],
    ];
    return (
      <Grid.Row verticalAlign="middle">
        <Grid.Column width={6} textAlign="right">
          Font family :{' '}
        </Grid.Column>
        <Grid.Column width={1} />
        <Grid.Column width={4}>
          {styleVariables.fontFamilySansSerif}
        </Grid.Column>
        <Grid.Column width={6} textAlign="right">
          Font family (large text and titles):{' '}
        </Grid.Column>
        <Grid.Column width={1} />
        <Grid.Column width={4}>{styleVariables.fontFamilyTitle}</Grid.Column>
        <Grid.Column width={6} textAlign="right">
          Font color :{' '}
        </Grid.Column>
        <Grid.Column width={1} />
        <Grid.Column width={4}>
          {renderColor(styleVariables.fontColorBase)}
        </Grid.Column>
        <Grid.Column width={6} textAlign="right">
          Font color (lighter) :{' '}
        </Grid.Column>
        <Grid.Column width={1} />
        <Grid.Column width={4}>
          {renderColor(styleVariables.fontColorLighter)}
        </Grid.Column>
        <Grid.Column width={16} as="h3">
          Font sizes
        </Grid.Column>
        <Grid.Column width={16} as="i">
          NB : 1rem is 14px.
        </Grid.Column>

        {fontSizes.map(([sizeName, fontSize]) =>
          fontWeights.map(([weightName, fontWeight]) => (
            <Grid.Column
              width={Math.round(16 / fontWeights.length)}
              style={{
                margin: `${styleVariables.spaceMedium} ${styleVariables.spaceNormal}`,
                lineHeight: 1.6,
                fontSize,
                fontWeight,
              }}
              key={`${fontSize}-${fontWeight}`}
            >
              size &quot;{sizeName}&quot; ({fontSize}) / weight &quot;
              {weightName}&quot; ({fontWeight})
            </Grid.Column>
          ))
        )}
      </Grid.Row>
    );
  };

  render() {
    return (
      <PageLayout>
        <Grid container style={{ margin: styleVariables.spaceNormal }}>
          <Grid.Row as="h1">Design system</Grid.Row>
          <Grid.Row as="h2">Text</Grid.Row>
          {this.renderTextElements()}
          <Grid.Row as="h2">Colors</Grid.Row>
          {this.renderColors()}
          <Grid.Row as="h2">Components</Grid.Row>
          <Grid.Row as="h3">Buttons</Grid.Row>
          {this.renderButtons()}
          <Grid.Row as="h3">Inputs</Grid.Row>
          {this.renderInputs()}
          <Grid.Row as="h3">Select</Grid.Row>
          {this.renderDropDowns()}
          <Grid.Row as="h3" />
        </Grid>
      </PageLayout>
    );
  }
}

ComponentDesignPage.propTypes = {};

ComponentDesignPage.defaultProps = {};
