import React from 'react';

const method = () => {
  throw new Error('Intentional error.');
};

function IntentionalErrorPage() {
  method();
  return <div>Intentional error page</div>;
}

IntentionalErrorPage.propTypes = {};

export default IntentionalErrorPage;
