import { actions as toastrActions } from 'react-redux-toastr';

import { api } from 'actions/utils';

export const actionTypes = {
  SET_CONTROLLER_MODULE: 'SET_CONTROLLER_MODULE',
  SET_CONTROLLER: 'SET_CONTROLLER',
  SET_TASK_PARAMETER: 'SET_TASK_PARAMETER',
  SET_TASK_COMMENT: 'SET_TASK_COMMENT',
  FETCH_CONTROLLERS_REQUEST: 'FETCH_CONTROLLERS_REQUEST',
  FETCH_CONTROLLERS_FAILURE: 'FETCH_CONTROLLERS_FAILURE',
  FETCH_CONTROLLERS_SUCCESS: 'FETCH_CONTROLLERS_SUCCESS',
  FETCH_TASKS_REQUEST: 'FETCH_TASKS_REQUEST',
  FETCH_TASKS_FAILURE: 'FETCH_TASKS_FAILURE',
  FETCH_TASKS_SUCCESS: 'FETCH_TASKS_SUCCESS',
  RUN_CONTROLLER_REQUEST: 'RUN_CONTROLLER_REQUEST',
  RUN_CONTROLLER_FAILURE: 'RUN_CONTROLLER_FAILURE',
  RUN_CONTROLLER_SUCCESS: 'RUN_CONTROLLER_SUCCESS',
  REVOKE_TASK_REQUEST: 'REVOKE_TASKS_REQUEST',
  REVOKE_TASK_FAILURE: 'REVOKE_TASKS_FAILURE',
  REVOKE_TASK_SUCCESS: 'REVOKE_TASKS_SUCCESS',
};

export const setController =
  (_, { value }) =>
  (dispatch, getState) => {
    let { module } = getState().operations;
    if (!module) {
      [module] = Object.entries(getState().operations.controllers).find(
        ([, { controllers }]) => controllers.find(({ name }) => name === value)
      );
      dispatch({
        module,
        type: actionTypes.SET_CONTROLLER_MODULE,
      });
    }

    const controller = getState().operations.controllers[
      module
    ].controllers.find(({ name }) => name === value);
    const taskParameters = {};
    controller.parameters.forEach((param) => {
      taskParameters[param.dest] = param.default;
    });
    dispatch({
      controller,
      taskParameters,
      type: actionTypes.SET_CONTROLLER,
    });
  };

export const setControllerModule = (_, { value }) => ({
  module: value,
  type: actionTypes.SET_CONTROLLER_MODULE,
});

export const setTaskParameters = (name, value) => ({
  name,
  value,
  type: actionTypes.SET_TASK_PARAMETER,
});

export const setTaskComment = (value) => ({
  value,
  type: actionTypes.SET_TASK_COMMENT,
});

export const fetchTaskControllers = () => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_CONTROLLERS_REQUEST });
  let controllers;
  try {
    controllers = await api.get('task_controllers');
  } catch (error) {
    return dispatch({ type: actionTypes.FETCH_CONTROLLERS_FAILURE, error });
  }
  return dispatch({
    type: actionTypes.FETCH_CONTROLLERS_SUCCESS,
    controllers: controllers.data,
  });
};

export const fetchTasks =
  (id = null, queryString = null) =>
  async (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_TASKS_REQUEST,
    });
    let tasks;
    let route = 'task';
    if (id) route = `${route}/${id}`;
    if (queryString)
      route = `${route}?query=${encodeURIComponent(queryString)}`;
    try {
      tasks = await api.get(route);
    } catch (error) {
      return dispatch({ type: actionTypes.FETCH_TASKS_FAILURE });
    }
    return dispatch({
      type: actionTypes.FETCH_TASKS_SUCCESS,
      tasks: tasks.data,
      id,
    });
  };

export const runTaskController =
  (name, parameters, dryRun) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.RUN_CONTROLLER_REQUEST });
    const {
      operations: { taskComment },
    } = getState();
    let taskRequest;
    try {
      taskRequest = await api.post('task_controllers', {
        name,
        parameters,
        dry_run: dryRun,
        comment: taskComment,
      });
      dispatch(
        toastrActions.add({
          type: 'success',
          title: 'Task launched',
          // message: `Could not launch task, check monitoring`,
          options: { timeOut: 2000 },
        })
      );
    } catch (error) {
      dispatch(
        toastrActions.add({
          type: 'error',
          title: 'Error',
          message: 'Could not launch task, check monitoring',
          options: { timeOut: 4000 },
        })
      );
      return dispatch({ type: actionTypes.RUN_CONTROLLER_FAILURE, error });
    }
    return dispatch({
      type: actionTypes.RUN_CONTROLLER_SUCCESS,
      task: taskRequest.data,
      dryRun,
    });
  };

export const revokeTaskController = (taskId) => async (dispatch) => {
  dispatch({ type: actionTypes.REVOKE_TASK_REQUEST });
  let revokeTaskRequest;
  try {
    revokeTaskRequest = await api.get(`/task/${taskId}/revoke`);
    dispatch(
      toastrActions.add({
        type: 'success',
        title: 'Revoking task',
        message: 'Task successfully revoked !',
        options: { timeOut: 2000 },
      })
    );
  } catch (error) {
    dispatch(
      toastrActions.add({
        type: 'error',
        title: 'Error',
        message: 'Could not revoke task, check monitoring',
        options: { timeOut: 4000 },
      })
    );
    return dispatch({ type: actionTypes.REVOKE_TASK_FAILURE, error });
  }
  return dispatch({
    type: actionTypes.REVOKE_TASK_SUCCESS,
    revoke: revokeTaskRequest.data,
  });
};
