import React, { Component } from 'react';
import { connect } from 'react-redux';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Grid, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';

import { maybeFetchOntologies } from 'actions/entities';
import { api } from 'actions/utils';

import { PageLayout } from 'components/ui/layout/Page';
import PredictionTable from 'components/ui/prediction/table';
import Voice from 'components/voice/Voice';

import {
  colorPrimary,
  fontSizeLarge,
  spaceXLarge,
} from 'assets/style/variables';

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const LargeInput = styled(TextArea)`
  &&&& {
    width: 80%;
    display: flex;
  }
`;
const VoiceContainer = styled.div`
  padding-left: 2%;
  align-self: center;
`;

const TextAnalyzed = styled.div`
  font-size: ${fontSizeLarge};
  color: ${colorPrimary};
`;

class LivePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      predictions: {},
      chunks: [],
    };
    this.setTranscript = this.setTranscript.bind(this);
    this.setInputText = debounce(this.setInputText.bind(this), 1000);
    this.setIsRecording = this.setIsRecording.bind(this);
  }

  componentDidMount() {
    const { dispatchMaybeFetchOntologies } = this.props;
    dispatchMaybeFetchOntologies();
  }

  setIsRecording(isRecording) {
    this.setState({ isRecording });
  }

  async setTranscript(transcript) {
    if (transcript) {
      api.post('/classify', { text: transcript }).then((response) => {
        this.setState({
          predictions: response.data.predictions,
          chunks: response.data.chunks,
        });
      });
    }
  }

  setInputText(event, data) {
    this.setTranscript(data.value);
  }

  renderPredictions(chunk, reviewChunkIndex) {
    const { predictions } = this.state;
    return (
      <Grid.Row columns={2} key={`livePreds-preds-${reviewChunkIndex}`}>
        <Grid.Column width={16}>
          <TextAnalyzed>{chunk}</TextAnalyzed>
        </Grid.Column>
        {Object.entries(predictions).map(
          ([ontologyName, predictionsAllChunks], it0) => (
            <Grid.Column key={`livePreds-table-${ontologyName}`} width={8}>
              <PredictionTable
                predictions={predictionsAllChunks[reviewChunkIndex]}
                tablePosition={it0}
                title={ontologyName}
                withVocabulary
              />
            </Grid.Column>
          )
        )}
      </Grid.Row>
    );
  }

  render() {
    const { chunks, isRecording } = this.state;
    return (
      <PageLayout>
        <Grid divided="vertically" style={{ padding: spaceXLarge, margin: 0 }}>
          <Grid.Row>
            <InputContainer>
              <LargeInput
                onChange={this.setInputText}
                placeholder="Comment..."
              />
              <VoiceContainer>
                <Voice
                  setTranscript={this.setTranscript}
                  setIsRecording={this.setIsRecording}
                  isRecording={isRecording}
                />
              </VoiceContainer>
            </InputContainer>
          </Grid.Row>
          {chunks.length
            ? chunks.map((chunk, chunkIndex) =>
                this.renderPredictions(chunk, chunkIndex)
              )
            : null}
        </Grid>
      </PageLayout>
    );
  }
}

LivePage.propTypes = {
  dispatchMaybeFetchOntologies: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => ({
    dispatchMaybeFetchOntologies: () => dispatch(maybeFetchOntologies()),
  })
)(LivePage);
