import { actions as toastrActions } from 'react-redux-toastr';

import { api, getQueryString } from 'actions/utils';

export const actionTypes = {
  FETCH_ONTOLOGIES_REQUEST: 'FETCH_ONTOLOGIES_REQUEST',
  FETCH_ONTOLOGIES_FAILURE: 'FETCH_ONTOLOGIES_FAILURE',
  FETCH_ONTOLOGIES_SUCCESS: 'FETCH_ONTOLOGIES_SUCCESS',
  FETCH_REVIEWS_REQUEST: 'FETCH_REVIEWS_REQUEST',
  FETCH_REVIEWS_SUCCESS: 'FETCH_REVIEWS_SUCCESS',
  FETCH_REVIEWS_FAILURE: 'FETCH_REVIEWS_FAILURE',
  TYPE_LOADING: 'TYPE_LOADING',
  UPDATE_PREDICTION_APPROVAL: 'UPDATE_PREDICTION_APPROVAL',
  UNFOLD_PREDICTION_APPROVAL: 'UNFOLD_PREDICTION_APPROVAL',
  UPDATE_REVIEW_DISABLED_REQUEST: 'UPDATE_REVIEW_DISABLED_REQUEST',
  UPDATE_REVIEW_DISABLED_SUCCESS: 'UPDATE_REVIEW_DISABLED_SUCCESS',
  UPDATE_REVIEW_DISABLED_FAILURE: 'UPDATE_REVIEW_DISABLED_FAILURE',
  UPDATE_REVIEW_SKIP: 'UPDATE_REVIEW_SKIP',
  VALIDATE_REVIEW_REQUEST: 'VALIDATE_REVIEW_REQUEST',
  VALIDATE_REVIEW_FAILURE: 'VALIDATE_REVIEW_FAILURE',
  VALIDATE_REVIEW_SUCCESS: 'VALIDATE_REVIEW_SUCCESS',
  SET_EXTRA_CONCEPT: 'SET_EXTRA_CONCEPT',
  FETCH_BRANDS_REQUEST: 'FETCH_BRANDS_REQUEST',
  FETCH_BRANDS_FAILURE: 'FETCH_BRANDS_FAILURE',
  FETCH_BRANDS_SUCCESS: 'FETCH_BRANDS_SUCCESS',
  FETCH_PRODUCTS_REQUEST: 'FETCH_PRODUCTS_REQUEST',
  FETCH_PRODUCTS_FAILURE: 'FETCH_PRODUCTS_FAILURE',
  FETCH_PRODUCTS_SUCCESS: 'FETCH_PRODUCTS_SUCCESS',
  FETCH_GENERATIONS_REQUEST: 'FETCH_GENERATIONS_REQUEST',
  FETCH_GENERATIONS_FAILURE: 'FETCH_GENERATIONS_FAILURE',
  FETCH_GENERATIONS_SUCCESS: 'FETCH_GENERATIONS_SUCCESS',
  FETCH_TEXTS_STATUS_REQUEST: 'FETCH_TEXTS_STATUS_REQUEST',
  FETCH_TEXTS_STATUS_FAILURE: 'FETCH_TEXTS_STATUS_FAILURE',
  FETCH_TEXTS_STATUS_SUCCESS: 'FETCH_TEXTS_STATUS_SUCCESS',
  SET_TEXTS_STATUS: 'SET_TEXTS_STATUS',
  SET_SOURCE_FILTER: 'SET_SOURCE_FILTER',
  SET_BRAND_FILTER: 'SET_BRAND_FILTER',
  SET_PRODUCT_FILTER: 'SET_PRODUCT_FILTER',
  SET_GENERATION_FILTER: 'SET_GENERATION_FILTER',
  RESET_REVIEWS: 'RESET_REVIEWS',
  SET_CONCEPT_FILTER: 'SET_CONCEPT_FILTER',
  SET_TEXTS_FILTER: 'SET_TEXTS_FILTER',
  SET_REVIEW_IDS_FILTER: 'SET_REVIEW_IDS_FILTER',
};

function reviewHasValidatedClassifications(review) {
  // We check that classification lists are empty for all (potential) ontology keys.
  for (const ontologyName of Object.keys(review.validated_classifications)) {
    if (review.validated_classifications[ontologyName].items_texts.length > 0) {
      return true;
    }
  }
  return false;
}

/**
 * DEPRECATED - we allow validation of previously validated/disabled reviews
 *
 * @export
 * @param {*} reviews
 * @returns
 */
export function getReviewsToValidate(reviews) {
  return reviews.filter(
    (r) => !r.disabled && !r.validated && !reviewHasValidatedClassifications(r)
  );
}

const batchSize = 5;

export const fetchOntologies = () => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.FETCH_ONTOLOGIES_REQUEST,
  });
  let response;
  try {
    response = await api.get('ontologies');
  } catch (error) {
    return dispatch({
      type: actionTypes.FETCH_ONTOLOGIES_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  return dispatch({
    type: actionTypes.FETCH_ONTOLOGIES_SUCCESS,
    data: response.data,
  });
};

export const setExtraConcept = (ontology, chunkIndex, value) => ({
  type: actionTypes.SET_EXTRA_CONCEPT,
  ontology,
  chunkIndex,
  value,
});

export const setTextsStatus = (chunkIndex, value) => ({
  type: actionTypes.SET_TEXTS_STATUS,
  chunkIndex,
  value,
});

export const loadMoreReviews =
  (resetReviews = false) =>
  async (dispatch, getState) => {
    if (resetReviews) {
      dispatch({
        type: actionTypes.RESET_REVIEWS,
      });
    }
    const state = getState();
    let queryParameters = {
      n: batchSize,
      offset: state.validation.offset,
      disabled: false,
    };

    if (state.validation.reviewFilters.reviewIds) {
      const reviewIds = state.validation.reviewFilters.reviewIds.split(',');
      queryParameters = {
        ...queryParameters,
        id__in: reviewIds,
        // Set limit to at least the number of given reviewIds
        n: Math.max(batchSize, reviewIds.length),
      };
    } else {
      queryParameters = {
        ...queryParameters,
        source: state.validation.reviewFilters.sourceIds,
        brand: state.validation.reviewFilters.brandId,
        product: state.validation.reviewFilters.productId,
        generation: state.validation.reviewFilters.generationId,
        concept: state.validation.reviewFilters.conceptId,
        texts: state.validation.reviewFilters.texts,
        non_empty_texts: true,
        validated: false,
        ambiguous: false,
      };
    }
    const queryString = getQueryString(queryParameters);
    dispatch({
      type: actionTypes.FETCH_REVIEWS_REQUEST,
    });
    let reviews = [];
    try {
      const response = await api.get(`reviews?${queryString}`);
      reviews = [...response.data];
    } catch (error) {
      dispatch(
        toastrActions.add({
          type: 'error',
          title: 'Error',
          message: `Could not query reviews. Details: ${
            error.response && error.response.data
              ? error.response.data.message
              : 'Check filters'
          }`,
          options: { timeOut: 4000 },
        })
      );
      return dispatch({
        reviews,
        type: actionTypes.FETCH_REVIEWS_FAILURE,
      });
    }
    if (!reviews.length) {
      dispatch(
        toastrActions.add({
          type: 'warning',
          title: 'Warning',
          message: 'Could not find reviews with these filters.',
          options: {
            options: { timeOut: 10000 },
            showCloseButton: true,
          },
        })
      );
    }
    return dispatch({
      reviews,
      type: actionTypes.FETCH_REVIEWS_SUCCESS,
    });
  };

export const setSourceFilter = (sourceIds) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.SET_SOURCE_FILTER,
    sourceIds,
  });

  dispatch({
    type: actionTypes.RESET_REVIEWS,
  });
  dispatch(loadMoreReviews());
};

export const setBrandFilter = (brandId) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_BRAND_FILTER,
    brandId,
  });

  dispatch({
    type: actionTypes.RESET_REVIEWS,
  });
  dispatch(loadMoreReviews());
};

export const setProductFilter = (productId) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PRODUCT_FILTER,
    productId,
  });

  dispatch({
    type: actionTypes.RESET_REVIEWS,
  });
  dispatch(loadMoreReviews());
};

export const setGenerationFilter = (generationId) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_GENERATION_FILTER,
    generationId,
  });

  dispatch({
    type: actionTypes.RESET_REVIEWS,
  });
  dispatch(loadMoreReviews());
};

export const setConceptFilter = (ontologyName, conceptId) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CONCEPT_FILTER,
    ontologyName,
    conceptId,
  });

  dispatch({
    type: actionTypes.RESET_REVIEWS,
  });
  dispatch(loadMoreReviews());
};

export const onTextFilterChange = (e, { value }) => ({
  type: actionTypes.SET_TEXTS_FILTER,
  value,
});

export const onReviewIdsFilterChange = (e, { value }) => ({
  type: actionTypes.SET_REVIEW_IDS_FILTER,
  value,
});

function onReviewUpdate(dispatch, state) {
  // We compare to 2 because we have the state before the update we've done here.
  if (getReviewsToValidate(state.validation.reviews).length === 1) {
    dispatch(loadMoreReviews());
  }
}

export const validateReview = (reviewId) => async (dispatch, getState) => {
  // reviewId is only there to sanity-check.
  // We are validating using the current review state (if it matches the id).
  const state = getState();
  if (state.validation.current_review_id !== reviewId) {
    dispatch(
      toastrActions.add({
        type: 'error',
        title: 'Error',
        message:
          'Unable to validate review. Please refresh the page and try again.',
        options: { timeOut: 4000 },
      })
    );
    return dispatch({
      type: actionTypes.VALIDATE_REVIEW_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }

  const review = state.validation.reviews.find((r) => r.id === reviewId);
  const validatedClassifications = {};
  for (const [ontology, validation] of Object.entries(
    state.validation.ontologyToValidation
  )) {
    validatedClassifications[ontology] = {
      // If review was already validated, keep other fields (and notably `id` to avoid database duplication)
      ...(review.validated_classifications
        ? review.validated_classifications[ontology]
        : {}),
      // Replace `texts` field validations with current ones
      items_texts: validation.map(
        ({ predictionApproval, extraConceptIds }, i) =>
          // Iterate on chunk to generate classifications
          // Add validated classifications only if chunk is qualified
          state.validation.reviewTextsStatus[i] === 'Q'
            ? Object.keys(predictionApproval)
                // We only create the 'ground truth' positive predictions for consistency.
                .filter((conceptId) => predictionApproval[conceptId])
                .concat(extraConceptIds)
                .map((conceptId) => ({
                  db_concept: { id: conceptId },
                  probability: 1,
                  validated: true,
                }))
            : []
      ),
    };
  }
  const validatedTextsStatus = state.validation.reviewTextsStatus.map(
    (item) => ({
      // Id is sufficient for schema to map to status constant
      status: { id: item },
      validated: true,
    })
  );

  let response;
  try {
    response = await api.put('reviews', {
      ...review,
      validated: true,
      validated_classifications: validatedClassifications,
      validated_texts_status_classifications: validatedTextsStatus,
    });
  } catch (error) {
    dispatch(
      toastrActions.add({
        type: 'error',
        title: 'Error',
        message: `Could not validate review.
          Details: ${
            error.response
              ? `${error.response.statusText} - ${JSON.stringify(
                  error.response.data
                )}`
              : error
          }`,
        options: { timeOut: 4000 },
      })
    );
    return dispatch({
      type: actionTypes.VALIDATE_REVIEW_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }

  // Slightly dirty but it just works.
  window.scrollTo({
    left: 0,
    top: 0,
    behavior: 'smooth',
  });

  dispatch({
    type: actionTypes.VALIDATE_REVIEW_SUCCESS,
    data: response.data,
  });

  dispatch(
    toastrActions.add({
      type: 'success',
      title: 'Success',
      message: 'Review has been validated',
      options: { timeOut: 4000 },
    })
  );

  return onReviewUpdate(dispatch, state);
};

export const fetchBrands = () => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.FETCH_BRANDS_REQUEST,
  });
  let response;
  try {
    response = await api.get('brands');
  } catch (error) {
    return dispatch({
      type: actionTypes.FETCH_BRANDS_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  return dispatch({
    type: actionTypes.FETCH_BRANDS_SUCCESS,
    data: response.data,
  });
};

export const fetchProducts = (brandId) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.FETCH_PRODUCTS_REQUEST,
  });
  let response;
  try {
    response = await api.get(`brands/${brandId}/products`);
  } catch (error) {
    return dispatch({
      type: actionTypes.FETCH_PRODUCTS_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  return dispatch({
    type: actionTypes.FETCH_PRODUCTS_SUCCESS,
    data: response.data,
  });
};

export const fetchGenerations = (productId) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.FETCH_GENERATIONS_REQUEST,
  });
  let response;
  try {
    response = await api.get(`products/${productId}/generations`);
  } catch (error) {
    return dispatch({
      type: actionTypes.FETCH_GENERATIONS_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  return dispatch({
    type: actionTypes.FETCH_GENERATIONS_SUCCESS,
    data: response.data,
  });
};

export const updatePredictionApproval =
  (ontology, chunkIndex, conceptId, approved) => (dispatch, getState) => {
    dispatch({
      type: actionTypes.UPDATE_PREDICTION_APPROVAL,
      ontology,
      chunkIndex,
      conceptId,
      value: approved,
    });
  };

export const unfoldPredictionApproval =
  (ontology, chunkIndex) => (dispatch, getState) => {
    dispatch({
      type: actionTypes.UNFOLD_PREDICTION_APPROVAL,
      ontology,
      chunkIndex,
    });
  };

export const skipReview = (reviewId) => (dispatch, getState) => {
  onReviewUpdate(dispatch, getState());
  dispatch({
    type: actionTypes.UPDATE_REVIEW_SKIP,
    reviewId,
  });
};
export const updateReviewDisabled =
  (disabled, reviewId) => async (dispatch, getState) => {
    const state = getState();
    dispatch({ type: actionTypes.UPDATE_REVIEW_DISABLED_REQUEST });

    const review = state.validation.reviews.find((r) => r.id === reviewId);

    let response;
    try {
      response = await api.put('reviews', { ...review, disabled });
    } catch (error) {
      dispatch(
        toastrActions.add({
          type: 'error',
          title: 'Error',
          message: 'Sorry, could not mark review as disabled. Check logs.',
          options: { timeOut: 4000 },
        })
      );
      return dispatch({
        type: actionTypes.UPDATE_REVIEW_DISABLED_FAILURE,
        // Commented out because it is non serializable  thus not accepted by redux
        // error,
      });
    }

    dispatch({
      type: actionTypes.UPDATE_REVIEW_DISABLED_SUCCESS,
      data: response.data,
    });

    onReviewUpdate(dispatch, state);

    return dispatch(
      toastrActions.add({
        type: 'success',
        title: 'Success',
        message: 'Review has been marked as disabled',
        options: { timeOut: 4000 },
      })
    );
  };

export const fetchTextsStatus = () => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.FETCH_TEXTS_STATUS_REQUEST,
  });
  let response;
  try {
    response = await api.get('texts-status');
  } catch (error) {
    return dispatch({
      type: actionTypes.FETCH_TEXTS_STATUS_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  return dispatch({
    type: actionTypes.FETCH_TEXTS_STATUS_SUCCESS,
    // values: response.data.map(item => ({ key: item.id, value: item.id, text: item.name })),
    values: response.data.map((item) => ({
      key: item.id,
      value: item.id,
      text: `${item.name} (${item.id})`,
      desc: item.description,
    })),
  });
};
