import { createGlobalStyle } from 'styled-components';

import {
  backgroundColorBase,
  fontColorBase,
  fontFamilySansSerif,
} from 'assets/style/variables';

export default createGlobalStyle`
  #root {
    height: 100%;
    width: 100%;
  }
  html, body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: ${backgroundColorBase};
    line-height: initial;
    // This constraint is problematic when page is scrollable, as right side 
    // of the page is overlayed by scrollbar
    // min-width: 100vw;
  }
  body {
    font-family: ${fontFamilySansSerif};
    color: ${fontColorBase};
  }
  i {
    font-style: normal;
  }
  input[type=number] {
    text-align: right !important;
  }
  /* Add space above close button in redux toaster, as it overlap progress bar */
  .redux-toastr .toastr .close-toastr {
    top: 10px;
  }

  .recharts-tooltip-wrapper {
    outline: none;
  }

  i.flag.es.ca:before,
  i.flag.catalonia:before {
    background-position: 0 -936px
}
`;
