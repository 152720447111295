import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { finishLoading, startLoading } from 'actions/ui';
import { receiveLogout } from 'actions/user';
import { api } from 'actions/utils';
import {
  appConfigSelector,
  isAuthenticatedSelector,
  isBwSelector,
} from 'selectors/user';

import { getHomePath } from 'utils/routing';

const LOGIN_REDIRECTED_ERROR_CODES = [403];
const HOME_REDIRECTED_ERROR_CODES = [404, 500];

/**
 * Create an axios middleware to handle response and response errors before methods
 * `then` or `catch` is called, with ability to dispatch events.
 *
 * @param {*} axiosClient the axios client
 * @returns a Redux middleware.
 */
const createAxiosMiddleware = (
  navigate,
  isAuthenticated,
  isBw,
  { analysis, campaign },
  pathname,
  searchParamsString,
  dispatch
) => {
  api.interceptors.response.use(
    (response) => {
      dispatch(finishLoading());
      return response;
    },
    (error) => {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('is error', error);
      }
      dispatch(finishLoading());
      if (error.response) {
        if (LOGIN_REDIRECTED_ERROR_CODES.includes(error.response.status)) {
          if (isAuthenticated) {
            dispatch(receiveLogout()).then(() => {
              if (!pathname.startsWith('/login')) {
                navigate('/login', {
                  replace: true,
                  state: {
                    previousPathname: `${pathname}${searchParamsString}`,
                  },
                });
              }
            });
          } else {
            navigate('/login', {
              replace: true,
              state: { previousPathname: `${pathname}${searchParamsString}` },
            });
          }
        } else if (
          HOME_REDIRECTED_ERROR_CODES.includes(error.response.status)
        ) {
          navigate(getHomePath(isAuthenticated, isBw, analysis, campaign));
        }
      }
      return Promise.reject(error);
    }
  );
  api.interceptors.request.use((requestConfig) => {
    dispatch(startLoading());
    return requestConfig;
  });
  return (next) => (action) => next(action);
};

export default function AxiosInterceptorNavigate() {
  const middleware = useRef(null);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const isBw = useSelector(isBwSelector);
  const appConfig = useSelector(appConfigSelector);
  useEffect(() => {
    middleware.current = createAxiosMiddleware(
      navigate,
      isAuthenticated,
      isBw,
      appConfig,
      pathname,
      search,
      dispatch
    );
  }, []);
  return <div id="q-interceptor" />;
}
